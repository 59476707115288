import React from "react";
import { Images } from "../../assets";

interface CardData {
  title: string;
}

interface CardProps {
  data: CardData;
}

const SourceNetworkCard: React.FC<CardProps> = ({ data }) => {
  return (
    <div className="bg-backgroundLight max-w-[550px] p-4 border border-borderBackgroundLight rounded-[20px] space-y-4 font-ModAberMano ">
      <div className=" border-b border-borderBackgroundLight pb-2 text-primaryLight ">
        {data.title}
      </div>
      <p className="text-primaryLight text-base">
        TARGET CHAIN ID : <span>42</span>
      </p>
      <p className="text-primaryLight text-base">
        NONCE : <span>2</span>
      </p>
      <p className="text-primaryLight text-base">
        TIMESTAMP : <span>23100 (5 minutes ago)</span>
      </p>
    </div>
  );
};

export default SourceNetworkCard;
