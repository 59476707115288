import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import { ReactIcons } from "../../assets";
import clsx from "clsx";

const Portal = ({ children }: { children: ReactNode }) => {
  return createPortal(children, document.body);
};

type Size = "sm" | "md" | "lg";

interface DialogProps {
  show: boolean;
  onHide: () => void;
  children: ReactNode;
  className?: string;
  size?: Size;
  title?: string | ReactNode;
  showCloseButton?: boolean;
  showTitle?: boolean;
  autoHide?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  show,
  onHide,
  children,
  className,
  size = "md",
  title,
  showCloseButton = false,
  showTitle = true,
}) => {
  if (!show) return null;

  const classes = clsx(
    "border-xl fixed left-1/2 top-1/2 z-50 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col rounded-xl bg-background p-5 shadow-lg md:flex-col",
    {
      "max-w-[430px] sm:max-w-[580px]": size === "lg",
      "max-w-[300px] sm:max-w-[400px]": size === "md",
      "max-w-[300px]": size === "sm",
    },
    className
  );

  return (
    <Portal>
      <div className="fixed inset-0 z-40 bg-black bg-opacity-50 backdrop-blur-sm backdrop-filter"></div>
      <section className={classes}>
        <header className="flex justify-between">
          {showTitle && (
            <p className="text-xl font-normal text-white">{title}</p>
          )}
          {showCloseButton && (
            <button onClick={onHide}>
              <ReactIcons.IoMdClose className="text-xl text-primaryLight" />
            </button>
          )}
        </header>
        <>{children}</>
      </section>
    </Portal>
  );
};
