import React from "react";
import { Images, ReactIcons } from "../assets"; // Assume Images has necessary icons
import { Block } from "../interfaces";
import {
  formatBlockTimestamp,
  formatTimeAgoWithTimestamp,
} from "../utils/helper";
import { BlockSkeleton } from "./skeleton/BlockSkeleton";
interface FinalizedBlockCardProps {
  block: Block;
  isLoading: boolean;
}
const FinalizedBlockCard: React.FC<FinalizedBlockCardProps> = ({
  block,
  isLoading,
}) => {
  return (
    <>
      <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight font-ModAberMano max-w-6xl border border-borderBackgroundLight space-y-5">
        <div className="flex items-center">
          <div className="flex items-center gap-2 text-sm w-[22%] ">
            <img src={Images.questionMarkIcon} alt="" />
            BLOCK HEIGHT:
          </div>
          <div className="text-lg font-bold flex items-center gap-3">
            {block?.number}
          </div>
        </div>
        {/* deprected */}
        <div className="hidden items-center  ">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" /> STATUS:
          </div>
          <div className="flex items-center">
            <div className="inline-flex items-center gap-1 justify-center px-2 py-2 text-xs font-semibold text-successColorContrast bg-successColor rounded">
              <span className="text-green-500">
                <ReactIcons.FaRegCheckCircle className="text-black mr-[2px]" />
              </span>
              <span>Finalized</span>
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" /> TIMESTAMP:
          </div>
          <div className="flex items-center">
            <span className="mr-2">
              <ReactIcons.MdOutlineWatchLater />
            </span>
            {formatTimeAgoWithTimestamp(block?.timestamp)}
          </div>
        </div>
        {/* deprected */}
        <div className="hidden items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            STANDARD BLOCKS:
          </div>
          {"--"}
          {/* <div>
          <span className="px-2 py-1.5 bg-backgroundDark rounded-md">
            {standardBlocks}
          </span>{" "}
          Blocks
        </div> */}
        </div>

        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            TRANSACTIONS:
          </div>
          <div>
            <span className="px-2 py-1.5 bg-backgroundDark rounded-md">
              {block?.txCount} Transactions
            </span>{" "}
            {/* deprecated */}
            <span className="hidden px-2 py-1.5 bg-backgroundDark rounded-md">
              {block?.txCount} Contract Internal Transactions
            </span>{" "}
            In This Block
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" /> MINER:
          </div>
          <div className="flex items-center">{block?.miner}</div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" /> GAS USED:
          </div>
          <div className="flex items-center">{block?.gasUsed}</div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" /> GAS USED:
          </div>
          <div className="flex items-center">{block?.gasLimit}</div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            BASE FEE PER GAS:
          </div>
          <div className="flex items-center">{block?.baseFeePerGas}</div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            DIFFICULTY:
          </div>
          <div className="flex items-center">{block?.difficulty}</div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            TOTAL DIFFICULTY:
          </div>
          <div className="flex items-center">{block?.totalDifficulty}</div>
        </div>

        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            BLOCK REWARD:
          </div>
          <span>{block?.txsFees}</span>
        </div>

        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%] self-start">
            <img src={Images.questionMarkIcon} alt="" /> ACCUMULATED REWARD:
          </div>

          {block.rewards.length === 0 ? (
            "0"
          ) : (
            <ul className="flex items-center flex-col gap-2">
              {block.rewards.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            HASH:
          </div>
          <span>{block?.hash}</span>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            PARENT HASH:
          </div>
          <span>{block?.parentHash}</span>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-2  text-sm w-[22%]">
            <img src={Images.questionMarkIcon} alt="" />
            NONCE:
          </div>
          <span>{block?.nonce}</span>
        </div>
      </div>
    </>
  );
};

export default FinalizedBlockCard;
