import { useEffect } from "react";
import { Dialog } from "../components/ui/Dialog";
import { useConnect } from "wagmi";
import { walletImages } from "../utils/contants";

const ConnectWalletDialog = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  const { connectors, connect, isSuccess, isError } = useConnect();

  useEffect(() => {
    if (isSuccess) {
      console.log("Connection successful!");
      onHide();
    }
    if (isError) {
      console.error("Connection failed.");
    }
  }, [isSuccess, isError, onHide]);

  const handleConnect = (connector: any) => {
    connect({ connector });
  };

  return (
    <>
      <Dialog
        show={show}
        onHide={onHide}
        title="Connect Wallet"
        showCloseButton
        showTitle
      >
        <div className="my-4 grid grid-cols-3 gap-3 sm:grid sm:grid-cols-4">
          {connectors.map((connector) => {
            if (
              typeof window !== "undefined" &&
              window.innerWidth < 640 &&
              connector.name === "MetaMask"
            ) {
              return null;
            }
            return (
              <div
                className="flex h-[70px] w-[70px] cursor-pointer flex-col items-center justify-center rounded-lg hover:bg-backgroundDark"
                key={connector.id}
                onClick={() => handleConnect(connector)}
              >
                <img
                  className="h-6 w-6 cursor-pointer"
                  src={
                    connector?.name === "WalletConnect" && !connector?.icon
                      ? walletImages["walletConnect"]
                      : connector?.icon || "fallback-image.png"
                  }
                  alt={connector?.name}
                />
                <button className="mt-2 cursor-pointer text-[9px] text-primaryLight">
                  {connector?.name}
                </button>
              </div>
            );
          })}
        </div>
      </Dialog>
    </>
  );
};

export default ConnectWalletDialog;
