import React from "react";
import { Images } from "../assets"; // Adjust the import path as necessary
import { Link } from "react-router-dom";
import { getTimeAgo, truncateTxHash } from "../utils/helper";
import { Transaction } from "../interfaces";
import { TableSkeleton } from "./skeleton/Index";
import { useStateInfo } from "../hooks";

interface TransactionListProps {
  transactionList: Transaction[];
  isLoading: boolean;
  headerVisible?: boolean;
}

const TransactionsTable: React.FC<TransactionListProps> = ({
  transactionList,
  isLoading,
  headerVisible = true,
}) => {
  const {
    data: stateInfo,
    error: stateInfoError,
    isLoading: stateInfoLoading,
  } = useStateInfo();

  // Function to convert transactionList to CSV
  const downloadCSV = () => {
    // Define CSV column headers
    const headers = ["TXN HASH", "BLOCK", "AGE", "FROM", "TO", "VALUE", "FEE"];

    // Map the transaction list into CSV format
    const csvRows = transactionList.map((txn) => [
      txn.hash,
      txn.blockNumber,
      getTimeAgo(txn.timestamp),
      txn.from,
      txn.to,
      txn.value,
      txn.fee,
    ]);

    // Combine headers and rows
    const csvContent = [
      headers.join(","), // Join headers with commas
      ...csvRows.map((row) => row.join(",")), // Join each row with commas
    ].join("\n"); // Join each row with a newline

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "transactions.csv"; // Set the downloaded file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link after download
  };

  return (
    <>
      <div className="flex justify-between text-primaryLight items-center font-ModAberMano my-7">
        <div className="flex items-center gap-2.5">
          <img src={Images.qpLogoIcon} alt={"gplogo"} />
          <span className="font-bold text-lg">Transactions</span>
        </div>
        {headerVisible && (
          <p className="text-[10px] font-normal">
            More than &gt; {stateInfo?.totalTransactions - 5} transactions found
          </p>
        )}
      </div>

      <div className="overflow-x-auto  px-7 py-4 border border-borderBackgroundLight rounded-[20px] font-ModAberMano bg-backgroundLight">
        <table className="min-w-full text-primaryLight">
          <thead>
            <tr className="text-left">
              <th className="p-2.5 text-base font-medium">TXN HASH</th>
              <th className="p-2.5 text-base font-medium">BLOCK</th>
              <th className="p-2.5 text-base font-medium">AGE</th>
              <th className="p-2.5 text-base font-medium">FROM</th>
              <th className="p-2.5 text-base font-medium">TO</th>
              <th className="p-2.5 text-base font-medium">VALUE</th>
              <th className="p-2.5 text-base font-medium">FEE</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableSkeleton />
            ) : transactionList?.length ? (
              transactionList.map((txn, index) => (
                <tr
                  key={index}
                  className="border-t border-borderBackgroundLight text-xs"
                >
                  <td className="p-2.5 ">
                    <Link to={`/tx/${txn.hash}`}>
                      <span>{truncateTxHash(txn.hash, 13)}</span>
                    </Link>
                  </td>
                  <td className="p-2.5">
                    <Link to={`/block/${txn.blockNumber}`}>
                      <span>{txn.blockNumber}</span>
                    </Link>
                  </td>
                  <td className="p-2.5">{getTimeAgo(txn.timestamp)}</td>
                  <td className="p-2.5">
                    <Link to={`/address/${txn.from}`}>
                      <span>{truncateTxHash(txn.from, 13)}</span>
                    </Link>
                  </td>
                  <td className="p-2.5">
                    <Link to={`/address/${txn.to}`}>
                      <span>{truncateTxHash(txn.to, 13)}</span>
                    </Link>
                  </td>
                  <td className="p-2.5">{txn.value}</td>
                  <td className="p-2.5">{txn.fee}</td>
                </tr>
              ))
            ) : (
              <p className="mx-auto w-100 text-center">No Transaction Found</p>
            )}
          </tbody>
          {!isLoading && (
            <tfoot>
              <tr>
                <td colSpan={8} className="p-2.5 text-right">
                  <span
                    className="cursor-pointer text-primaryLight text-[10px] font-normal"
                    onClick={downloadCSV}
                  >
                    [ Download CSV Export ]
                  </span>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export default TransactionsTable;
