import React from "react";
import { Images } from "../assets"; // Update this import path as needed

interface ContractDetailsProps {
  contractName: string;
  compilerVersion: string;
  optimization: number;
  otherSettings: string;
}

const ContractDetailsHeader: React.FC<ContractDetailsProps> = ({
  contractName,
  compilerVersion,
  optimization,
  otherSettings,
}) => {
  return (
    <div>
      {/* Contract Verification */}
      <p className="flex gap-2">
        <img src={Images.successIcon} alt="Success Icon" />
        <span className="text-base font-medium">
          Contract Source Code Verified (Exact Match)
        </span>
      </p>

      {/* Contract Information */}
      <div className="flex items-center justify-between max-w-[80%] mb-12 mt-6">
        <div className="space-y-2">
          <p className="text-primary">
            Contract Name:{" "}
            <span className="text-primaryLight">{contractName}</span>
          </p>
          <p className="text-primary">
            Compiler Version:{" "}
            <span className="text-primaryLight">{compilerVersion}</span>
          </p>
        </div>
        <div className="space-y-2">
          <p className="text-primary">
            Optimization Enabled:{" "}
            <span className="text-primaryLight">{`${
              optimization > 1
                ? `yes with ${optimization} optimizations`
                : `yes with ${optimization} optimization`
            }`}</span>
          </p>
          <p className="text-primary">
            Other Settings:{" "}
            <span className="text-primaryLight">{otherSettings}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContractDetailsHeader;
