import React, { useState } from "react";
import { CodeBlock, atomOneDark } from "react-code-blocks";
import { Images } from "../assets";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="border border-borderBackgroundLight bg-backgroundDark my-4 px-2 rounded-md">
      <button
        className="flex justify-between items-center w-full py-3 text-left"
        onClick={toggleAccordion}
      >
        <span className="text-primaryLight font-medium text-sm">{title}</span>
        <span className="flex items-center gap-3">
          <img src={Images.accordianLInkIcon} alt="" />{" "}
          {isOpen ? (
            <img src={Images.arrowUp} alt="" />
          ) : (
            <img src={Images.arrowDown} alt="" />
          )}
        </span>
      </button>
      {isOpen && <div className="p-4  bg-backgroundDark ">{children}</div>}
    </div>
  );
};
export default Accordion;
