import React from "react";
import { Images } from "../assets"; // Assuming you have these image imports already
import { useNavigate } from "react-router-dom";

interface ValidatorData {
  rank: string;
  name: string;
  address: string;
  firstBlock: number;
  lastBlock: number;
  oneDay: number;
  sevenDays: number;
  thirtyDays: number;
  active: string;
}

interface ValidatorTableProps {
  data: ValidatorData[];
}

const ValidatorTable: React.FC<ValidatorTableProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleRowClick = (validatorRank: string) => {
    navigate(`/validator-details/${validatorRank}`);
  };

  return (
    <>
      <div className="flex justify-between text-primaryLight items-center font-ModAberMano my-7">
        <div className="flex items-center gap-2">
          <img src={Images.qpLogoIcon} alt={"gplogo"} />
          <span className="font-bold text-lg">Validators Top Leaderboard</span>
        </div>
      </div>

      <div className="overflow-x-auto p-5 border border-borderBackgroundLight rounded-[20px] font-ModAberMano bg-backgroundLight">
        <table className="min-w-full text-primaryLight">
          <thead>
            <tr className="text-left">
              <th className="p-2 text-base font-medium">Rank</th>
              <th className="p-2 text-base font-medium">Address</th>
              <th className="p-2 text-base font-medium">First Block</th>
              <th className="p-2 text-base font-medium">Last Block</th>
              <th className="p-2 text-base font-medium">1 Day</th>
              <th className="p-2 text-base font-medium">7 Days</th>
              <th className="p-2 text-base font-medium">30 Days</th>
              <th className="p-2 text-base font-medium">Active</th>
            </tr>
          </thead>
          <tbody>
            {data.map((validator, index) => (
              <tr
                key={index}
                className="border-t border-borderBackgroundLight text-xs cursor-pointer"
                onClick={() => handleRowClick(validator.rank)}
              >
                <td className="p-2 flex items-center gap-10">
                  <div className="flex items-center gap-1">
                    <span>{validator.rank}</span>
                  </div>
                </td>
                <td className="p-2">{validator.address}</td>
                <td className="p-2">{validator.firstBlock}</td>
                <td className="p-2">{validator.lastBlock}</td>
                <td className="p-2">{validator.oneDay}</td>
                <td className="p-2">{validator.sevenDays}</td>
                <td className="p-2">{validator.thirtyDays}</td>
                <td className="p-2">{validator.active}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ValidatorTable;
