import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AddressDetailsInfo from "./pages/addressDetailsInfo/Index";
import Transactions from "./pages/Transactions";
import Blocks from "./pages/Blocks";
import BlockDetails from "./pages/BlockDetails";
import TransactionDetails from "./pages/TransactionDetails";
import ValidatorLeaderBoard from "./pages/ValidatorLeaderBoard";
import ContractVerification from "./pages/ContractVerification";
import SmartContractSearch from "./pages/SmartContractSearch";
import VerifyAndPublish from "./pages/VerifyAndPublish";
import PersonalInfo from "./pages/AccountSettings";
import SmartContractInitailSearch from "./pages/SmartContractInitailSearch";
import Index from "./pages/transactionLifeCycle/Index";
import PageNotFound from "./pages/PageNotFound";
import MinerStakePage from "./pages/minerStakePage/Index";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blocks" element={<Blocks />} />
        <Route path="/txs" element={<Transactions />} />
        <Route path="/address/:address" element={<AddressDetailsInfo />} />
        <Route path="/block/:id" element={<BlockDetails />} />
        <Route path="/tx/:id" element={<TransactionDetails />} />
        <Route
          path="/validators/leaderboard"
          element={<ValidatorLeaderBoard />}
        />
        <Route path="/verify" element={<ContractVerification />} />
        <Route
          path="/smart-contract/search"
          element={<SmartContractSearch />}
        />
        <Route path="/verify/publish" element={<VerifyAndPublish />} />
        <Route path="/personal-info" element={<PersonalInfo />} />
        <Route
          path="/contract/search"
          element={<SmartContractInitailSearch />}
        />
        <Route path="/transaction/lifecycle" element={<Index />} />
        <Route path="/qp-miner-stake" element={<MinerStakePage />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
