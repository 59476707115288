// import { http, createConfig } from "wagmi";
// import { mainnet, sepolia, arbitrum } from "wagmi/chains";

// export const config = createConfig({
//   chains: [mainnet, sepolia],
//   transports: {
//     [mainnet.id]: http(),
//     [sepolia.id]: http(),
//   },
// });

import { http, createConfig } from "wagmi";
import {
  mainnet,
  sepolia,
  bsc,
  arbitrum,
  base,
  optimism,
  avalanche,
  scroll,
} from "wagmi/chains";
import { defineChain } from "viem";
import { walletConnect } from "wagmi/connectors";

const projectId = "3fbb6bba6f1de962d911bb5b5c9dba88";
declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

const ferrum = defineChain({
  id: 26100,
  name: "Ferrum",
  nativeCurrency: { name: "FRM", symbol: "FRM", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://testnet.dev.svcs.ferrumnetwork.io"] },
  },
  blockExplorers: {
    default: {
      name: "Frmscan",
      url: "https://testnet-explorer.svcs.ferrumnetwork.io",
    },
  },
});
export const config = createConfig({
  chains: [
    mainnet,
    sepolia,
    bsc,
    base,
    optimism,
    avalanche,
    scroll,
    arbitrum,
    ferrum,
  ],
  connectors: [walletConnect({ projectId })],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [bsc.id]: http(),
    [arbitrum.id]: http(),
    [base.id]: http(),
    [optimism.id]: http(),
    [avalanche.id]: http(),
    [scroll.id]: http(),
    [ferrum.id]: http(),
  },
});
