import React from "react";
import MainLayout from "../layout/MainLayout";
import StatsCard from "../components/StatsCard";
import LatestBlocks from "../components/LatestBlocks";
import LatestTransactions from "../components/LatestTransactions";
import { useBlocks, useTransactions } from "../hooks/index";
import SearchBar from "../components/SearchBar";
import { useStateInfo } from "../hooks/index";
import { StatsCardSkeleton } from "../components/skeleton/Index";
const Home: React.FC = () => {
  const {
    data: blocks,
    error: blockError,
    isLoading: blocksLoading,
  } = useBlocks(1, 20);

  const {
    data: transactions,
    error: transactionsError,
    isLoading: transactionsLoading,
  } = useTransactions(1, 20);

  const {
    data: stateInfo,
    error: stateInfoError,
    isLoading: stateInfoLoading,
  } = useStateInfo();

  // Error state
  if (blockError || transactionsError || stateInfoError)
    return (
      <div>An error occurred while fetching data. Please try again later.</div>
    );
  return (
    <MainLayout>
      <SearchBar />
      {stateInfoLoading ? (
        <StatsCardSkeleton />
      ) : (
        <StatsCard
          frmPrice={stateInfo?.frmData?.price}
          frmPriceChangePercentage={stateInfo?.frmData?.priceChangePercentage}
          frmMarketCap={stateInfo?.frmData?.marketCap}
          frmMarketCapChangePercentage={
            stateInfo?.frmData?.marketCapChangePercentage
          }
          transactions={stateInfo?.totalTransactions}
          frmVolume24h={stateInfo?.graphData}
        />
      )}

      <div className="max-w-[1382px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 my-16">
        {blocksLoading ? (
          <LatestBlocks blocks={[]} isLoading={true} />
        ) : (
          <LatestBlocks blocks={blocks?.results} isLoading={false} />
        )}

        {transactionsLoading ? (
          <LatestTransactions transactions={[]} isLoading={true} />
        ) : (
          <LatestTransactions
            transactions={transactions?.results}
            isLoading={false}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default Home;
