import React from "react";
import { Images } from "../assets";

interface ContractCardProps {
  address: string;
  details: string;
  contractName: string;
  lastUpdated: string;
  txns: string;
}

const ContractCard: React.FC<ContractCardProps> = ({
  address,
  details,
  contractName,
  lastUpdated,
  txns,
}) => {
  return (
    <div className="bg-backgroundLight p-4 rounded-md shadow-md font-ModAberMano border border-borderBackgroundLight space-y-4">
      <div className="text-base text-primaryLight ">{address}</div>
      <div className="text-xs text-primaryLight">{details}</div>
      <div className="flex gap-7 text-xs text-backgroundLight">
        <div className="flex items-center gap-2">
          <img src={Images.contractNameIcon} alt="" />
          <div className="text-xs text-primaryLight">{contractName}</div>
        </div>
        <div className="text-xs text-primaryLight">
          Last Updated: {lastUpdated}
        </div>
        <div className="text-xs text-primaryLight">{txns}</div>
      </div>
    </div>
  );
};

export default ContractCard;
