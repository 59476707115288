import React from "react";
import { Images } from "../../assets";

const WithdrawCard: React.FC = () => {
  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight border-backgroundLight">
      <h2 className="text-lg font-medium text-primaryLight pb-1 mb-4 border-b border-borderBackgroundLight">
        Withdraw
      </h2>
      <div className=" flex items-center justify-between">
        <span className="uppercase text-primary text-sm">Available Date</span>
        <span className="uppercase text-primary text-sm">AMOUNT</span>
      </div>
      <div className="mb-3.5 flex items-center justify-between gap-3 border-b border-borderBackgroundLight mt-6 pb-3">
        <span className="font-medium text-sm">2 days 6 hrs ago</span>
        <span className="font-normal text-sm">0.028 FRM</span>
      </div>
      <div className="mb-3.5 flex items-center justify-between gap-3 border-b border-borderBackgroundLight mt-8 pb-3">
        <span className="font-medium text-sm">2 days 6 hrs ago</span>
        <span className="font-normal text-sm">0.028 FRM</span>
      </div>
      <div className="mb-3.5 flex items-center justify-between gap-3 border-b border-borderBackgroundLight mt-8 pb-3">
        <span className="font-medium text-sm">2 days 6 hrs ago</span>
        <span className="font-normal text-sm">0.028 FRM</span>
      </div>
      <div className="mb-3.5 flex items-center justify-between gap-3 mt-8 pb-3">
        <span className="font-medium text-sm">2 days 6 hrs ago</span>
        <span className="font-normal text-sm">0.028 FRM</span>
      </div>
    </div>
  );
};

export default WithdrawCard;
