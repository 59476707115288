import React, { useState } from "react";
import { Images, ReactIcons } from "../assets";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-background font-ModAberMano">
      <div className="max-w-[1382px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24 relative">
          <div className="flex items-center">
            <a href="/">
              <img src={Images.qpLogo} alt="" />
            </a>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a
                target="_blank"
                href="https://swap.multiswap.network/swap"
                className="text-primaryLight hover:text-primary px-3 py-2 rounded-md text-base font-normal"
              >
                Swap
              </a>
              {/* <Link
                to={`/validators/leaderboard`}
                className="text-primaryLight hover:text-primary px-3 py-2 rounded-md text-base font-normal"
              >
                Validators
              </Link> */}
              <div className="relative group">
                <a
                  href="#"
                  className="text-primaryLight hover:text-primary px-3 py-2 rounded-md text-base font-normal group flex items-center gap-1"
                >
                  <span>More</span>{" "}
                  <ReactIcons.MdOutlineKeyboardArrowDown size={22} />
                </a>
                <div className="absolute -right-5 top-7 mt-2 w-[900px] hidden group-hover:block bg-background rounded-md shadow-lg p-4 z-50">
                  <div className="flex justify-between px-10 py-8">
                    <div className="w-[40%] space-y-6">
                      <h3 className="text-primary font-bold">
                        TOOLS & SERVICES
                      </h3>
                      <p className="text-primaryLight mt-2">
                        Discover more of Ferrum Network tools and services in
                        one place.
                      </p>
                      <img
                        src={Images.frmFullLogo}
                        alt="Ferrum Network"
                        className="mt-4"
                      />
                    </div>
                    <div className="w-[30%] px-10">
                      <h3 className="text-primary font-bold">OUR PRODUCTS</h3>
                      <ul className="mt-2 space-y-3">
                        <li>
                          <a
                            target="_blank"
                            href="https://bridge.ferrum.network/frm"
                            className="text-primaryLight hover:text-primary"
                          >
                            Bridge
                          </a>
                        </li>
                        {/* <li>
                          <a
                            href="#"
                            className="text-primaryLight hover:text-primary"
                          >
                            Crucible
                          </a>
                        </li> */}
                        <li>
                          <a
                            target="_blank"
                            href="https://gateway.ferrumnetwork.io/"
                            className="text-primaryLight hover:text-primary"
                          >
                            Gateway
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://swap.multiswap.network/swap"
                            className="text-primaryLight hover:text-primary"
                          >
                            MultiSwap
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="w-[30%] ">
                      <h3 className="text-primary font-bold">DEVELOPERS</h3>
                      <ul className="mt-2 space-y-3">
                        <li>
                          <a
                            target="_blank"
                            href="https://docs.ferrumnetwork.io/"
                            className="text-primaryLight hover:text-primary"
                          >
                            API Documentation
                          </a>
                        </li>
                        {/* <li>
                          <Link
                            to={`/transaction/lifecycle`}
                            className="text-primaryLight hover:text-primary"
                          >
                            Transaction Lifecycle
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to="https://sourcify.dev/#/verifier"
                            target="_blank"
                            className="text-primaryLight hover:text-primary"
                          >
                            <div className="f-pt-1">Verify Contract</div>
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to={`/contract/search`}
                            className="text-primaryLight hover:text-primary"
                          >
                            Search Smart Contract
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            to={`/validators/leaderboard`}
                            className="text-primaryLight hover:text-primary"
                          >
                            Validators Leaderboard
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            to={`/qp-miner-stake`}
                            className="text-primaryLight hover:text-primary"
                          >
                            Miner Stake
                          </Link>
                        </li> */}
                        <li>
                          <a
                            href="https://discord.gg/u3qjKEq7Mp"
                            className="text-primaryLight hover:text-primary"
                          >
                            Support & FAQ
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <a
                href="#"
                className="text-primaryLight hover:text-primary px-3 py-2 rounded-md text-base font-normal"
              >
                Sign In
              </a> */}
            </div>
          </div>

          <div className="flex md:hidden">
            <button
              onClick={toggleMenu}
              className="text-primaryLight hover:text-primary focus:outline-none"
            >
              {isMenuOpen ? (
                <ReactIcons.AiOutlineClose className="text-primaryLight h-6 w-6" />
              ) : (
                <ReactIcons.AiOutlineMenu className="text-primaryLight h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="#"
              className="text-primaryLight hover:text-primary block px-3 py-2 rounded-md text-base font-normal"
            >
              Swap
            </a>
            {/* <a
              href="#"
              className="text-primaryLight hover:text-primary block px-3 py-2 rounded-md text-base font-normal"
            >
              Validators
            </a> */}
            <a
              href="#"
              className="text-primaryLight hover:text-primary block px-3 py-2 rounded-md text-base font-normal"
            >
              More
            </a>
            {/* <a
              href="#"
              className="text-primaryLight hover:text-primary block px-3 py-2 rounded-md text-base font-normal"
            >
              Sign In
            </a> */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
