import React from "react";
import { Images, ReactIcons } from "../../assets"; // Assume Images has necessary icons

interface TargetBlockMined {
  blockHash: string;
  miner: string;
  invalidBlock: boolean;
  state: number;
  totalValue: string;
  sourceChainId: string;
  nonce: number;
  timestamp: string;
  fixedFee: string;
}

interface TargetBlockMinedProps {
  data: TargetBlockMined;
}

const TargetBlockMinedCard: React.FC<TargetBlockMinedProps> = ({ data }) => {
  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-white font-ModAberMano max-w-[1150px] border border-borderBackgroundLight space-y-5">
      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> Block Hash:
        </div>
        <div className="flex items-center">{data.blockHash}</div>
      </div>

      <div className="flex items-center ">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> MINER:
        </div>
        <div className="flex items-center gap-2">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.miner}
          </span>
          <img className="w-4 h-4" src={Images.copyIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> INVALID BLOCK:
        </div>
        <div className="flex items-center gap-2">
          <span className=" bg-[#E84142] p-1 text-xs text-primaryLight  font-ModAberMano rounded-md">
            {data.invalidBlock ? "TRUE" : "FALSE"}
          </span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" />
          STATE:
        </div>
        <div className="flex items-center gap-2">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.state}
          </span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" />
          TOTAL VALUE:
        </div>
        <div className="flex items-center gap-2">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.totalValue}
          </span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" />
          sourceChainId:
        </div>
        <div className="flex items-center gap-4">
          <img className="w-4 h-4" src={Images.polygonIcon} alt="" />
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.sourceChainId}
          </span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> nonce:
        </div>
        <div className="flex items-center gap-4">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.nonce}
          </span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> Timestamp:
        </div>
        <div className="flex items-center gap-4">
          <img className="w-4 h-4" src={Images.polygonIcon} alt="" />
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.timestamp}
          </span>
          <span>MATIC</span>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> FIXED FEE:
        </div>
        <div className="flex items-center">
          <span className="mr-2">
            <ReactIcons.MdOutlineWatchLater />
          </span>
          <span>{data.fixedFee}</span>
        </div>
      </div>
    </div>
  );
};

export default TargetBlockMinedCard;
