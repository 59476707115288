import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Fetch multiple blocks with pagination
const fetchBlocks = async (pageNumber = 20, pageSize = 1) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/blocks?limit=${pageSize}&page=${pageNumber}`
  );
  return data;
};

// Custom hook for fetching multiple blocks
export const useBlocks = (pageNumber: number, pageSize: number) => {
  return useQuery({
    queryKey: ["blocks", { pageNumber, pageSize }],
    queryFn: () => fetchBlocks(pageNumber, pageSize),
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

// Fetch a block by ID or hash
const fetchBlockByIdOrHash = async (query: string) => {
  const isNumeric = /^\d+$/.test(query);
  const url = isNumeric
    ? `${process.env.REACT_APP_SERVER_URL}/blocks/${query}`
    : `${process.env.REACT_APP_SERVER_URL}/blocks/hash?blockHash=${query}`;

  const { data } = await axios.get(url);
  return data;
};

// Custom hook for fetching a single block by ID or hash
export const useBlockByIdOrHash = (blockIdentifier: string) => {
  return useQuery({
    queryKey: ["block", blockIdentifier],
    queryFn: () => fetchBlockByIdOrHash(blockIdentifier),
    staleTime: 10 * 1000,
    enabled: !!blockIdentifier,
  });
};
