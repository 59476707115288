import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchTransactions = async (page = 1, limit = 20, address?: any) => {
  const { data } = await axios.get(
    `${
      process.env.REACT_APP_SERVER_URL
    }/transactions?limit=${limit}&page=${page}&address=${
      address ? address : ""
    }`
  );
  return data;
};

export const useTransactions = (page: number, limit: number, address?: any) => {
  return useQuery({
    queryKey: ["transactions", page, limit, address],
    queryFn: () => fetchTransactions(page, limit, address),
  });
};

const fetchTransactionById = async (txId: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/transactions/${txId}`
  );
  return data.tx;
};

export const useTransactionById = (txId: string) => {
  return useQuery({
    queryKey: ["transaction", txId],
    queryFn: () => fetchTransactionById(txId),
    enabled: !!txId,
  });
};
const fetchInternalTransactionByAddress = async (
  page: number,
  limit: number,
  address: any
) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/transactions/internal-txs/${address}?limit=${limit}&page=${page}`
  );
  return data;
};
export const useInternalTxByAddress = (
  page: number,
  limit: number,
  address: any
) => {
  return useQuery({
    queryKey: ["internal-txs", address],
    queryFn: () => fetchInternalTransactionByAddress(page, limit, address),
    enabled: !!address,
  });
};

const fetchTokenTransferByAddress = async (
  page: number,
  limit: number,
  address: any
) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/transactions/token-transfer/${address}?limit=${limit}&page=${page}`
  );
  return data;
};
export const useTokenTransferByAddress = (
  page: number,
  limit: number,
  address: any
) => {
  return useQuery({
    queryKey: ["token-transfer-txs", address],
    queryFn: () => fetchTokenTransferByAddress(page, limit, address),
    enabled: !!address,
  });
};

const fetchLogsByAddress = async (
  page: number,
  limit: number,
  address: any
) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/accounts/addresses/${address}/logs?limit=${limit}&page=${page}`
  );
  return data;
};
export const useLogsByAddress = (page: number, limit: number, address: any) => {
  return useQuery({
    queryKey: ["logs", address],
    queryFn: () => fetchLogsByAddress(page, limit, address),
    enabled: !!address,
  });
};
