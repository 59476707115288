import React from "react";
import MainLayout from "../layout/MainLayout";
import { Images } from "../assets";
import { Link } from "react-router-dom";

const PageNotFound: React.FC = () => {
  return (
    <MainLayout>
      <div className="max-w-6xl mx-auto my-16">
        <div className="min-h-[500px] flex items-center justify-around  bg-center bg-no-repeat bg-pagenotfound radial-gradient(50% 50% at 50% 50%, rgba(17, 19, 21, 0) 0%, #111315 100%)">
          <div className=" text-white">
            <h1 className="text-4xl font-bold mb-4">Search Not Found</h1>
            <p className="text-lg mb-8">
              Oops! The search string you entered was:
              <br />
              [SEARCH INPUT]
            </p>
            <Link to="/">
              <button className="bg-primary  text-black px-6 py-2 rounded-lg">
                BACK HOME
              </button>
            </Link>
          </div>
          <div className="ml-8">
            <img src={Images.qplarglogo} alt="Logo" className="w-48" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PageNotFound;
