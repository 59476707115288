import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchStateInfo = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/info`);
  return data;
};

export const useStateInfo = () => {
  return useQuery({
    queryKey: ["searchResults"],
    queryFn: () => fetchStateInfo(),
    staleTime: 10 * 1000,
  });
};
