import React from "react";
import { Images } from "../assets";
import { truncateMiddleAddress } from "../utils/helper";

interface ContractDetailsOverview {
  block_number_balance_updated_at: number;
  coin_balance: string;
  creation_tx_hash: string;
  creator_address_hash: string;
  ens_domain_name: string | null;
  exchange_rate: string | null;
  has_beacon_chain_withdrawals: boolean;
  has_decompiled_code: boolean;
  has_logs: boolean;
  has_token_transfers: boolean;
  has_tokens: boolean;
  has_validated_blocks: boolean;
  hash: string;
  implementations: any[]; // If implementations have a more specific type, replace `any` with the appropriate type.
  is_contract: boolean;
  is_verified: boolean;
  metadata: string | null; // If metadata has a specific structure, replace `string | null` with that structure.
  name: string;
  private_tags: any[]; // If private_tags have a more specific type, replace `any` with the appropriate type.
  proxy_type: string;
  public_tags: any[]; // If public_tags have a more specific type, replace `any` with the appropriate type.
  token: string | null; // If token has a more specific structure, replace `string | null` with that structure.
  watchlist_address_id: string | null;
  watchlist_names: string[];
}

interface ContractOverviewInfoCardCardProps {
  data: ContractDetailsOverview;
  title: boolean;
}

const ContractOverviewInfoCard: React.FC<ContractOverviewInfoCardCardProps> = ({
  data,
  title,
}) => {
  return (
    <div className="text-primaryLight font-ModAberMano p-5 rounded-xl border border-borderBackgroundLight bg-backgroundLight min-w-[550px]">
      {data?.is_contract ? (
        <div className="flex items-center justify-between mb-4 border-b border-borderBackgroundLight pb-2">
          <h2 className="text-lg font-medium ">Contract Overview</h2>
          {/* <span className="flex items-center gap-3 text-xs">
            <span>USD Coin: USDC Token</span>{" "}
            <img src={Images.explorerIcon} alt="" />
          </span> */}
        </div>
      ) : (
        <h2 className="text-lg font-medium mb-4 border-b border-borderBackgroundLight pb-2">
          {data?.name}
        </h2>
      )}

      {data?.is_contract && !title ? (
        <div className="space-y-4">
          <div className="flex items-center gap-10">
            <span>BALANCE:</span>
            <span>{data.coin_balance}</span>
          </div>
          <div className=" flex items-center gap-3">
            <span>Last balance update:</span>
            <span className="flex gap-2">
              {/* <img src={Images.bscIcon} alt="" /> */}
              {data?.block_number_balance_updated_at}
            </span>
          </div>
          {/* <div className="flex items-center gap-10">
            <span>FRM Value:</span>
            <span>--</span>
          </div> */}
          {/* <div className="flex items-center gap-10">
            <span>TOKEN:</span>
            <select className="bg-background border border-borderBackgroundLight text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 max-w-[200px]">
              {data?.token?.map((tokenValue, index) => (
                <option key={index} value={tokenValue}>
                  {tokenValue}
                </option>
              ))}
            </select>
          </div> */}
        </div>
      ) : data?.creator_address_hash ? (
        <div className="flex flex-col gap-4">
          <div className=" flex items-center gap-3">
            <span>Contract Creator:</span>
            <span>{truncateMiddleAddress(data?.creator_address_hash)}</span>
          </div>
          <div className=" flex items-center gap-3">
            <span>Contraction Tx:</span>
            <span>{truncateMiddleAddress(data?.creation_tx_hash)}</span>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <span>--</span>
          <span>Not Available, login to update</span>
        </div>
      )}
    </div>
  );
};

export default ContractOverviewInfoCard;
