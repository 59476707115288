import React, { useState } from "react";
import { Images } from "../../assets";

const ContractDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Register (source)");

  const tabs = [
    { name: "Register (source)" },
    { name: "Mine (target)" },
    { name: "Finalize (target)" },
  ];

  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight font-ModAberMano max-w-6xl border border-borderBackgroundLight space-y-5">
      {/* Navigation Tabs */}
      <div className="flex">
        <div className="space-x-6">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`text-[10px] font-semibold px-4 py-2 rounded-lg ${
                activeTab === tab.name
                  ? "bg-primary text-primaryContrast"
                  : "bg-backgroundDark text-primaryLight"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>

      {/* Transaction and Logs Cards */}
      <div className="grid grid-cols-2 gap-6">
        {/* Transaction Card */}

        <div className="">
          <div className=" flex items-center gap-3 my-3">
            <img src={Images.bineryIcon} alt="" />
            <span className=" font-ModAberMano text-primaryLight text-base font-semibold">
              Transaction
            </span>
          </div>
          <div className="bg-backgroundDark rounded-lg p-4 space-y-4 border border-borderBackgroundLight font-ModAberMano">
            <ul className="flex  border-b border-borderBackgroundLight pb-2 w-full">
              <li className=" text-primary text-sm w-[30%]"> Item</li>
              <li className=" text-primary text-sm w-[20%]"> Result</li>
              <li className=" text-primary text-sm w-[50%]">Description</li>
            </ul>
            <ul className="flex  pb-2 w-full">
              <li className=" text-primaryLight text-xs w-[30%]"> Success</li>
              <li className=" text-primaryLight text-xs w-[20%]"> True</li>
              <li className=" text-primaryLight text-xs w-[50%]">
                Transaction registered successfully
              </li>
            </ul>
            <ul className="flex  pb-2 w-full">
              <li className=" text-primaryLight text-xs w-[30%]"> Explorer</li>
              <li className=" text-primaryLight text-xs w-[20%] flex items-center gap-2">
                <span>View</span> <img src={Images.explorerIcon} alt="" />
              </li>
              <li className=" text-primaryLight text-xs w-[50%]">
                Link to source chain explorer
              </li>
            </ul>
            <ul className="flex  pb-2 w-full">
              <li className=" text-primaryLight text-xs w-[30%]">
                {" "}
                Source Network
              </li>
              <li className=" text-primaryLight text-xs w-[20%] flex items-center gap-2">
                <img src={Images.polygonIcon} alt="" /> <span>Polygon</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Logs Card */}
        <div className="">
          <div className=" flex items-center gap-3 my-3">
            <img src={Images.bineryIcon} alt="" />
            <span className=" font-ModAberMano text-primaryLight text-base font-semibold">
              Logs
            </span>
          </div>
          <div className="bg-backgroundDark rounded-lg p-4 space-y-4 border border-borderBackgroundLight font-ModAberMano">
            <ul className="flex justify-between border-b border-borderBackgroundLight pb-2 w-full">
              <li className=" text-primary text-sm "> QP Registrar:</li>
              <li className=" text-primaryLight text-sm  flex items-center gap-2">
                <span>0x7F1Dc79E1fcB7592D705BA2b6B2074cB73F21915</span>
                <img src={Images.explorerIcon} alt="" />
              </li>
            </ul>
            <ul className="flex justify-between  pb-2 w-full">
              <li className=" text-primaryLight text-xs ">
                Transaction Registered:
              </li>
              <li className=" text-primaryLight text-xs  flex items-center gap-2">
                <span>0x7F1Dc79E1fcB7592D705BA2b6B2074cB73F21915</span>
              </li>
            </ul>
            <ul className="flex justify-between  pb-2 w-full">
              <li className=" text-primaryLight text-xs ">Source Chain ID:</li>
              <li className=" text-primaryLight text-xs  flex items-center gap-2">
                <img src={Images.polygonIcon} alt="" />
                <span>Polygon</span>
              </li>
            </ul>
            <ul className="flex justify-between  pb-2 w-full">
              <li className=" text-primaryLight text-xs ">Source Chain ID:</li>
              <li className=" text-primaryLight text-xs  flex items-center gap-2">
                <img src={Images.polygonIcon} alt="" />
                <span>Polygon</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
