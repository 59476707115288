import React from "react";
import { Images } from "../assets";
import VolumeChart from "./VolumeChart";

interface VolumeData {
  date: string;
  volume: number;
}
interface StatsCardProps {
  frmPrice: number;
  frmPriceChangePercentage: number;
  frmMarketCap: number;
  frmMarketCapChangePercentage: number;
  transactions: number;
  frmVolume24h: VolumeData[];
}

const StatsCard: React.FC<StatsCardProps> = ({
  frmPrice,
  frmPriceChangePercentage,
  frmMarketCap,
  frmMarketCapChangePercentage,
  transactions,
  frmVolume24h,
}) => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 font-ModAberMano  rounded-2xl p-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 border border-borderBackgroundLight">
      <div className="flex items-center space-x-2">
        <img src={Images.frmLogo} alt="FRM" className="w-8 h-8" />
        <div>
          <div className="text-sm font-normal text-primaryLight">FRM PRICE</div>
          <div className="flex items-center space-x-2">
            <span className="text-xl text-primaryLight">
              ${frmPrice?.toFixed(5)}
            </span>
            <span
              className={` text-xs rounded-xl px-2 py-1  ${
                frmPriceChangePercentage >= 0
                  ? "bg-successColor text-successColorContrast"
                  : "bg-red-500 text-primaryLight"
              }`}
            >
              ({frmPriceChangePercentage >= 0 ? "+" : ""}
              {frmPriceChangePercentage?.toFixed(2)}%)
            </span>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <img src={Images.marketCap} alt="Market Cap" className="w-8 h-8" />
        <div>
          <div className="text-sm font-normal text-primaryLight">
            MARKET CAP
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-xl text-primaryLight">
              ${frmMarketCap?.toLocaleString()}
            </span>
            <span
              className={`text-xs rounded-xl px-2 py-1 ${
                frmMarketCapChangePercentage >= 0
                  ? "bg-successColor text-successColorContrast"
                  : "bg-red-500 text-primaryLight"
              }`}
            >
              ({frmMarketCapChangePercentage >= 0 ? "+" : ""}
              {frmMarketCapChangePercentage?.toFixed(2)}%)
            </span>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <img
          src={Images.transactionsIcon}
          alt="Transactions"
          className="w-8 h-8"
        />
        <div>
          <div className="text-sm font-normal text-primaryLight">
            TRANSACTIONS
          </div>
          <span className="ttext-xl text-primaryLight">
            {transactions?.toLocaleString()}
          </span>
        </div>
      </div>

      <div className="flex flex-col items-center space-x-2 ">
        <div className="text-sm font-normal text-primaryLight self-start">
          VOLUME 24h
        </div>
        <VolumeChart data={frmVolume24h} />
      </div>
    </div>
  );
};

export default StatsCard;
