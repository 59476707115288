import React from "react";

interface PaginationProps {
  currentPage: number;
  recordsPerPage: number;
  totalPages: number;
  totalResults: number;
  onPageChange: (newPage: number) => void;
  onRecordsPerPageChange: (newRecordsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  recordsPerPage,
  totalPages,
  onPageChange,
  onRecordsPerPageChange,
}) => {
  return (
    <div className="flex justify-between items-center p-4 bg-backgroundDark text-primaryLight font-ModAberMano">
      <div className="flex items-center">
        <span className="mr-2">Show</span>
        <select
          className="bg-backgroundLight text-primaryLight px-2 py-1 rounded"
          value={recordsPerPage}
          onChange={(e) => onRecordsPerPageChange(Number(e.target.value))}
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        <span className="ml-2">Records</span>
      </div>

      <div className="flex items-center space-x-4">
        <button
          className="bg-backgroundLight text-primaryLight px-3 py-1 rounded"
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          First
        </button>
        <button
          className="bg-backgroundLight text-primaryLight px-2 py-1 rounded"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &gt;
        </button>
        <span className="bg-backgroundLight text-primaryLight px-2 py-1 rounded">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="bg-backgroundLight text-primaryLight px-2 py-1 rounded"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &lt;
        </button>
        <button
          className="bg-backgroundLight text-primaryLight px-3 py-1 rounded"
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          Last
        </button>
      </div>
    </div>
  );
};

export default Pagination;
