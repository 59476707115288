import React, { useState } from "react";
import { Images } from "../../assets";
import { CodeBlock, atomOneDark } from "react-code-blocks";
import ContractDetailsHeader from "../../components/ContractDetailsHeader";
import ContractCodeInfo from "./ContractCodeInfo";
import WriteMethods from "./WriteMethods";
import WriteAsProxyMethods from "./WriteAsProxyMethods";
import ReadMethods from "./ReadMethods";
import ReadAsProxyMethods from "./ReadAsProxyMethods";
import { ContractData } from "../../interfaces";

interface ContractDetailsProps {
  contractData: ContractData;
  isLoading?: boolean;
  contractDetailsInfo: ContractData;
  proxyContractDetailsInfo: ContractData;
  contractAddress: any;
  proxyContractAddress: any;
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  contractData,
  contractDetailsInfo,
  isLoading,
  contractAddress,
  proxyContractAddress,
  proxyContractDetailsInfo,
}) => {
  const [activeTab, setActiveTab] = useState("CODE");
  console.log({ contractData, proxyContractDetailsInfo });

  // Define tabs with conditions to show/hide them based on contract data availability.
  const tabs = [
    { name: "CODE", show: !!contractData?.source_code },
    { name: "READ CONTRACT", show: !!contractData?.has_methods_read },
    { name: "WRITE CONTRACT", show: !!contractData?.has_methods_write },
    {
      name: "READ AS PROXY",
      show: !!contractData?.has_methods_read_proxy,
    },
    {
      name: "WRITE AS PROXY",
      show: !!contractData?.has_methods_write_proxy,
    },
  ];
  // Filter out tabs that shouldn't be shown.
  const filteredTabs = tabs.filter((tab) => tab.show);

  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight font-ModAberMano max-w-6xl border border-borderBackgroundLight space-y-5">
      {/* Navigation Tabs */}
      <div className="flex justify-between">
        <div className="space-x-6">
          {filteredTabs.map((tab) => (
            <button
              key={tab.name}
              className={`text-[10px] font-semibold px-4 py-2 rounded-lg ${
                activeTab === tab.name
                  ? "bg-primary text-primaryContrast"
                  : "bg-backgroundDark text-primaryLight"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>

        <div className="border border-primary rounded-md flex">
          <input
            placeholder="Search Source Code"
            className="focus:outline-none bg-transparent px-2 rounded-md w-[90%]"
            type="text"
          />
          <img
            className="w-[21px] cursor-pointer"
            src={Images.inputSearchIcon}
            alt="Search Icon"
          />
        </div>
      </div>

      {isLoading && <div>Loading...</div>}

      {!isLoading && (
        <>
          {activeTab === "CODE" && (
            <ContractCodeInfo
              sourceCode={contractData?.source_code || ""}
              contractName={contractData?.name || ""}
              compilerVersion={contractData?.compiler_version || ""}
              optimization={contractData?.optimization_runs || 0}
              settingsCode={contractData?.compiler_settings || ""}
              contractAbi={contractData?.abi || []}
              creationCode={contractData?.creation_bytecode || ""}
            />
          )}
          {activeTab === "WRITE CONTRACT" && (
            <WriteMethods
              contractAddress={contractAddress as `0x${string}`}
              abi={contractDetailsInfo?.abi || []}
            />
          )}
          {activeTab === "WRITE AS PROXY" && (
            <WriteAsProxyMethods
              contractAddress={proxyContractAddress as `0x${string}`}
              abi={proxyContractDetailsInfo?.abi || []}
            />
          )}
          {activeTab === "READ CONTRACT" && (
            <ReadMethods
              contractAddress={contractAddress as `0x${string}`}
              abi={contractDetailsInfo?.abi || []}
            />
          )}
          {activeTab === "READ AS PROXY" && (
            <ReadAsProxyMethods
              contractAddress={proxyContractAddress as `0x${string}`}
              abi={proxyContractDetailsInfo?.abi || []}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ContractDetails;
