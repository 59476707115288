export const TableSkeleton: React.FC = () => (
  <>
    {[...Array(10)].map((_, index) => (
      <tr
        key={index}
        className="border-t border-borderBackgroundLight text-xs animate-pulse"
      >
        <td className="p-2.5">
          <div className="h-5 w-16 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-24 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-12 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-28 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-20 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-20 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-16 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-16 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-16 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-16 bg-backgroundDark rounded"></div>
        </td>
        <td className="p-2.5">
          <div className="h-5 w-16 bg-backgroundDark rounded"></div>
        </td>
      </tr>
    ))}
  </>
);
