import React from "react";
import { Images } from "../../assets";

const MinerDashboard: React.FC = () => {
  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight border-backgroundLight">
      <h2 className="text-lg font-medium text-primaryLight pb-1 mb-4 border-b border-borderBackgroundLight">
        Quantum Portal Miner Dashboard
      </h2>
      <div className="mb-3.5 flex items-center gap-3 ">
        <span className="font-medium text-primary uppercase  text-sm">
          QP Gateway Contract:{" "}
        </span>
        <span className="font-normal text-sm">
          0x4bba9b6bdfa66107...aa68b04a4d
        </span>
        <img src={Images.explorerIcon} alt="" />
      </div>
      <div className="mb-3.5 flex items-center gap-3">
        <span className="font-medium text-primary uppercase text-sm ">
          QP Miner Staking Contract:{" "}
        </span>
        <span className="font-normal text-sm">0x4bbab34107...aa68b04a4d</span>
        <img src={Images.explorerIcon} alt="" />
      </div>
      <div className="mb-3.5 flex items-center gap-3">
        <span className="font-medium text-primary uppercase text-sm ">
          Stake ID:
        </span>
        <span className="font-normal text-sm">0x4bbab34107...aa68b04a4d</span>
        <img src={Images.explorerIcon} alt="" />
      </div>
      <div className="mb-3.5 flex items-center gap-3 ">
        <span className="font-medium text-primary uppercase text-sm ">
          Base Token:
        </span>
        <span className="font-normal text-sm">0x4bbab34107...aa68b04a4d</span>
        <img src={Images.explorerIcon} alt="" />
      </div>
      <div className="border border-borderBackgroundLight w-full my-2"> </div>
      <div className="mb-3.5 flex items-center gap-3 ">
        <span className="font-medium text-primary uppercase text-sm ">
          Total staked:
        </span>
        <span className="font-normal text-sm">0</span>
      </div>
      <div className="mb-3.5 flex items-center gap-3 ">
        <span className="font-medium text-primary uppercase text-sm ">
          Total rewards:
        </span>
        <span className="font-normal text-sm">0</span>
      </div>
      <div className="mb-3.5 flex items-center gap-3 ">
        <span className="font-medium text-primary uppercase text-sm ">
          Minimum stake required:
        </span>
        <span className="font-normal text-sm">1000 FRM</span>
      </div>
    </div>
  );
};

export default MinerDashboard;
