import React from "react";
import { Images } from "../../assets";

const DecodeEvent = ({ tx }: any) => {
  console.log("contract logs ");
  console.log(tx?.length);

  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight font-ModAberMano max-w-[1150px] border border-borderBackgroundLight space-y-5">
      {tx ? (
        tx.map((log: any, index: number) => (
          <div
            key={index}
            className="space-y-4 border-b border-borderBackgroundLight py-6 text-white"
          >
            <div className="flex items-center">
              <div className="flex items-center gap-2 text-sm w-[28%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                Transaction:
              </div>
              <span>{log.tx_hash}</span>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2 text-sm w-[28%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                ADDRESS:
              </div>
              <span>{log.address.hash}</span>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2 text-sm w-[28%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                NAME:
              </div>
              <p className="w-[72%]">{log.decoded.method_call}</p>
            </div>

            <div className="flex items-start">
              <div className="flex items-center gap-2 text-sm w-[28%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                Decoded input data:
              </div>
              <div className="overflow-auto  rounded-md bg-background w-[75%]">
                <table className="table-auto border-none w-full text-left">
                  <thead className="bg-background ">
                    <tr className="border-b">
                      <th className="p-2">#</th>
                      <th className="p-2">Name</th>
                      <th className="p-2">Type</th>
                      <th className="p-2">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {log.decoded.parameters.map((param: any, index: number) => (
                      <tr key={index} className="">
                        <td className="p-2 border-b">{index + 1}</td>
                        <td className="p-2 border-b">{param.name}</td>
                        <td className="p-2 border-b">{param.type}</td>
                        <td className="p-2 border-b break-all">
                          {param.value.toString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="flex flex-col space-y-2">
            {log.decoded.parameters.map((topic, index) => (
              <div key={index} className="flex items-center gap-3 ">
                <p>{topic.name}</p>
                <p>{topic.value}</p>
              </div>
            ))}
          </div> */}
            </div>

            <div className="flex items-center">
              <div className="flex items-center gap-2  text-sm w-[28%] self-start">
                <img className="h-4 w-4" src={Images.questionMarkIcon} alt="" />
                DATA:
              </div>
              <div className="flex p-2 bg-backgroundDark rounded-md w-[75%] justify-between min-h-20 relative">
                <span className="text-sm break-all max-w-[80%]">
                  {log?.data}
                </span>
                {/* {copiedFields.data ? (
                  <ReactIcons.IoCheckmark className="w-5 h-5 cursor-pointer" />
                ) : (
                  <ReactIcons.IoCopyOutline
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => handleCopyClick(log.address.name, log?.data)}
                  />
                )} */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-white">No Logs Found</div>
      )}
    </div>
  );
};

export default DecodeEvent;
