import React from "react";
import { Images, ReactIcons } from "../../assets"; // Assume Images has necessary icons

interface TransactionData {
  timestamp: string;
  remoteContract: string;
  sourceMsgSender: string;
  sourceBeneficiary: string;
  token: string;
  amount: number;
  methods: string;
  gasFee: string;
  fixedFee: string;
}

interface remoteTransactionInterfaceProps {
  data: TransactionData;
}

const FinalizedBlockCard: React.FC<remoteTransactionInterfaceProps> = ({
  data,
}) => {
  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-white font-ModAberMano max-w-[1150px] border border-borderBackgroundLight space-y-5">
      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> TIMESTAMP:
        </div>
        <div className="flex items-center">
          <span className="mr-2">
            <ReactIcons.MdOutlineWatchLater />
          </span>
          {data.timestamp}
        </div>
      </div>

      <div className="flex items-center ">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> REMOTE CONTRACT:
        </div>
        <div className="flex items-center gap-2">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.remoteContract}
          </span>
          <img className="w-4 h-4" src={Images.explorerIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> SOURCE MSG SENDER:
        </div>
        <div className="flex items-center gap-2">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.sourceMsgSender}
          </span>
          <img className="w-4 h-4" src={Images.explorerIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" />
          SOURCE BENEFICIARY:
        </div>
        <div className="flex items-center gap-2">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.sourceBeneficiary}
          </span>
          <img className="w-4 h-4" src={Images.explorerIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" />
          TOKEN:
        </div>
        <div className="flex items-center gap-2">
          <img src={Images.bscIcon} alt="" />
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.token}
          </span>
          <img className="w-4 h-4" src={Images.explorerIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> AMOUNT:
        </div>
        <div className="flex items-center gap-4">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.amount} <span>USDC</span>
          </span>
          <img className="w-4 h-4" src={Images.explorerIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> METHODS:
        </div>
        <div className="flex items-center gap-4">
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.methods} <span>USDC</span>
          </span>
          <img className="w-4 h-4" src={Images.copyIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> GAS FEE:
        </div>
        <div className="flex items-center gap-4">
          <img className="w-4 h-4" src={Images.polygonIcon} alt="" />
          <span className="text-primaryLight text-base font-ModAberMano">
            {data.gasFee}
          </span>
          <img className="w-4 h-4" src={Images.copyIcon} alt="" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center gap-2  text-sm w-[22%]">
          <img src={Images.questionMarkIcon} alt="" /> FIXED FEE:
        </div>
        <div className="flex items-center">{data.fixedFee}</div>
      </div>
    </div>
  );
};

export default FinalizedBlockCard;
