import React from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import { validatorData } from "../utils/helper";
import ValidatorTable from "../components/ValidatorTable";

const ValidatorLeaderBoard: React.FC = () => {
  return (
    <MainLayout>
      <SearchBar />

      <div className="max-w-6xl mx-auto my-16">
        <ValidatorTable data={validatorData} />
      </div>
    </MainLayout>
  );
};

export default ValidatorLeaderBoard;
