export const BlockSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      <div className="h-8 bg-background rounded mb-4"></div>
      <div className="h-6 bg-background rounded mb-2 w-2/3"></div>
      <div className="h-6 bg-background rounded mb-2 w-1/3"></div>
      <div className="h-6 bg-background rounded mb-2 w-1/4"></div>
      <div className="h-12 bg-background rounded mb-6"></div>
    </div>
  );
};
