import React from "react";
import { Images } from "../assets";
import { Link } from "react-router-dom";

const ContractVerificationForm: React.FC = () => {
  return (
    <div className="p-8 bg-backgroundLight text-primaryLight rounded-[20px] font-ModAberMano border border-borderBackgroundLight">
      {/* <h2 className="text-xl font-bold mb-4">Verify & Publish Contract Source Code</h2> */}
      <p className="text-sm mb-4">
        Source Code Verification Provides Transparency For Users Interacting
        With Smart Contracts. By Uploading The Source Code, Etherscan Will Match
        The Compiled Code With That On The Blockchain. Just Like Contracts, A
        "Smart Contract" Should Provide End Users With More Information On What
        They Are "Digitally Signing" For And Give Users An Opportunity To Audit
        The Code To Independently Verify That It Actually Does What It Is
        Supposed To Do.
      </p>
      <p className="text-sm mb-4">
        Please Be Informed That Advanced Settings (E.G. BytecodeHash: "None" Or
        ViaIR: "True") Can Be Accessed Via Solidity (Standard-Json-Input)
        Verification Method. More Information Can Be Found Under Solidity's
        "Compiler Input And Output JSON Description" Documentation Section.
      </p>

      <form className="space-y-4 max-w-[700px] mx-auto mt-7">
        <div className="space-y-2  ">
          <label className="block text-sm mb-1" htmlFor="contractAddress">
            Please Enter The Contract Address You Would Like To Verify
          </label>
          <div className=" px-4 py-1 flex rounded-md  bg-backgroundDark border border-borderBackgroundLight">
            <img className="w-6 " src={Images.contractInputIcon} alt="" />
            <input
              id="contractAddress"
              type="text"
              className="ml-auto p-2 focus:outline-none bg-backgroundDark w-full "
              placeholder="0x...."
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm mb-1" htmlFor="compilerType">
            Please Select Compiler Type
          </label>
          <div className=" px-4 py-1 flex rounded-md  bg-backgroundDark border border-borderBackgroundLight">
            <img className="w-6 " src={Images.compilerInput} alt="" />
            <select
              id="licenseType"
              className="w-full p-2 bg-backgroundDark focus:outline-none"
            >
              <option>Please Select</option>
            </select>
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm mb-1" htmlFor="compilerType">
            Please select Open Source License Type
          </label>
          <div className=" px-4 py-1 flex rounded-md  bg-backgroundDark border border-borderBackgroundLight">
            <img className="w-6 " src={Images.licenceInput} alt="" />
            <select
              id="licenseType"
              className="w-full p-2 bg-backgroundDark focus:outline-none"
            >
              <option>Please Select</option>
            </select>
          </div>
        </div>

        <div className="flex items-center space-y-2">
          <div className="flex items-center mb-4">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-sm font-medium text-primaryLight"
            >
              I agree to the terms of service
            </label>
          </div>
        </div>

        <div className="text-center ">
          <Link to={"/verify/publish"}>
            <button className="w-[280px] my-11 mx-auto p-3 bg-primary text-primaryContrast font-bold rounded-md  text-center ">
              CONTINUE
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ContractVerificationForm;
